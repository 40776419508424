import React from 'react';
import './Services.css';
import consultoria from '../../assets/img/consultoria.png';
import apps from '../../assets/img/aplicaciones-nativas-e-hibridas.png';
import web from '../../assets/img/creamos-plataformas-web.png';
import userExperience from '../../assets/img/user-experience.png';
import interfaceDesign from '../../assets/img/interface-design.png';
import crm from '../../assets/img/desarrollo-de-plataformas-crm.png';
import {
    Card, CardImg,
    CardText,
    CardBody,
    CardTitle
} from 'reactstrap';
import Translate from './../../utils/Translate';

function Services() {
    return (
        <div id="servicios" className="contentServices">
            <div className="services-container">
                <h2 className="text-center titleServices">{Translate.get('ourServices', '')}</h2>
                <div className="sectionServices">
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={crm} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('crmWha', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('crmWhaDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={web} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('webAppsProgresive', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('webAppsProgresiveDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={apps} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('appsDevelopment', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('appsDevelopmentDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={interfaceDesign} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('uxuiTitle', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('uxuiDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={userExperience} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('ux', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('uxDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="card-element">
                        <Card className="cardServices">
                            <CardImg className="cardImage" top src={consultoria} />
                            <CardBody>
                                <CardTitle><h3 className="cardTitle">{Translate.get('consultancy', '')}</h3></CardTitle>
                                <CardText className="cardText">
                                    <p>{Translate.get('consultancyDesc', '')}</p>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;