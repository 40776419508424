import React from 'react';
import './Process.css';
import { FaLightbulb, FaSearch, FaPencilRuler, FaCode, FaCheckCircle, FaRocket } from 'react-icons/fa'; // React Icons
import Translate from './../../utils/Translate'; // Translate utility

function Process() {
    const steps = [
        { icon: <FaLightbulb className="process-icon" />, labelKey: 'initiate', defaultLabel: 'Initiate' },
        { icon: <FaSearch className="process-icon" />, labelKey: 'analyze', defaultLabel: 'Analyze' },
        { icon: <FaPencilRuler className="process-icon" />, labelKey: 'design', defaultLabel: 'Design' },
        { icon: <FaCode className="process-icon" />, labelKey: 'develop', defaultLabel: 'Develop' },
        { icon: <FaCheckCircle className="process-icon" />, labelKey: 'qa', defaultLabel: 'QA' },
        { icon: <FaRocket className="process-icon" />, labelKey: 'launch', defaultLabel: 'Launch' },
    ];

    return (
        <div id="proceso" className="text-center container-fluid process-container">
            <h2 className="titleProcess">{Translate.get('ourProcess', 'Our Process')}</h2>
            <div className="process-row">
                {steps.map((step, index) => (
                    <div key={index} className="process-step">
                        <div className="icon-circle">{step.icon}</div>
                        <span className="process-label">
                            {Translate.get(step.labelKey, step.defaultLabel)}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Process;