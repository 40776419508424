import Storage from 'localStorage'
/*
 *  import Translate from './../../utils/Translate';
 *   {Translate.get('', '')}
 */
export const translate = {
  es: {
      services: "Servicios",
      technologies: "Tecnologías",
      ourProcess: "Nuestro proceso",
      developSoftware: "Desarrollamos software",
      developCustomSoftware: "a la medida.",
      workingUnderstand: "Desde la idea hasta el lanzamiento, nuestro equipo de expertos lo ayudará a crear las soluciones de software personalizadas que su empresa necesita para mantenerse competitivo, en el panorama digital actual.",
      contact: "Cotización Gratuita",
      weTrust: "Ellos confían en nosotros",
      doYouHaveAProject: "¿Tienes un proyecto en mente ó necesitas una cotización?",
      consultantCall: "Uno de nuestros consultores te llamará sin costo",
      leaveYourContact: "Déjanos tus datos.",
      nameFirstName: "* Nombre y Apellido",
      exampleEmail: "* ejemplo@email.com",
      mobile: "* Celular",
      country: "* País",
      whatTypeDevelopment: "* ¿Qué tipo de desarrollo necesitas?",
      webPage: "Página Web",
      saasDevelopment: "Desarrollo de SaaS",
      apps: "Aplicaciones en iOS y Android",
      webAndApps: "Plataforma Web y Aplicaciones móviles",
      uxui: "Diseño de experiencia de usuario e interfaces",
      enhacement: "Mantenimiento y mejoras sobre un desarrollo actual",
      consultancy: "Consultoría",
      projectStatus: "* ¿En que estatus se encuentra tu proyecto?",
      iHaveIdea: "Tengo la idea",
      iHaveMVP: "Tengo un prototipo",
      isWorking: "Mi proyecto ya está funcionando",
      howBigIsYourBusiness: "* ¿De que tamaño considerás que es tu negocio?",
      sizeBusiness: "Independiente / Profesional / Emprendedor",
      startup: "Startup",
      llc: "Pyme",
      corporation: "Empresa",
      timeDoYouNeed: "* ¿En que tiempo necesitas tu proyecto?",
      days: "Días",
      weeks: "Semanas",
      months: "Meses",
      describeProject: "* Cuentanos brevemente, ¿De qué trata tu proyecto?",
      sent: "Enviar Formulario",
      ourServices: "Nuestros servicios",
      consultancyDesc: "Nos hacemos parte de tu negocio, para dar una visión clara en lo que somos buenos, crear tecnología, ¿Tienes dudas por donde empezar? Nosotros te podemos ayudar.",
      webAppsProgresive: "Desarrollo de aplicaciones web y móviles:",
      webAppsProgresiveDesc: "Construimos aplicaciones web y móviles atractivas y receptivas que les ayuden a llegar a más clientes y aumentar sus ventas.",
      appsDevelopment: "Agentes de IA, Modelos AI, Machine Learning, Bots",
      appsDevelopmentDesc: "Te ayudamos a incorporar tecnologías AI y ML a su negocio para hacerlo más eficiente e inteligente.",
      ux: "Diseño y desarrollo de UI/UX",
      uxDesc: "¿De que serviría una app si no es fácil de usar? Nos centramos en la simplicidad y en la usabilidad.",
      uxuiTitle: "SaaS y MVPs",
      uxuiDesc: "Desarrollamos SaaS te ayudamos a construir a esa primera versión de tu SaaS.",
      crmWha: "Desarrollo de software a medida",
      crmWhaDesc: "Creamos para ti soluciones de software personalizadas que se ajusten a sus necesidades y objetivos comerciales.",
      empathize: "Empatizar",
      define: "Definir",
      ideate: "Idear",
      develop: "Desarrollar",
      measure: "Lanzar",
      ourTechnologies: "Nuestras tecnologías",
      ourTechnologiesDesc: "Trabajamos con las mejores técnologias del mercado, para crear y lanzar productos rápidamente.",
      slogan: "Lo que sea dicho, sea hecho",
      information: "Información",
      thankYou: "¡Gracias! 🙌 En breve te contactaremos. 😊",
      initiate: "Iniciar",
      analyze: "Analizar",
      design: "Diseñar",
      develop: "Desarrollar",
      qa: "QA",
      launch: "Lanzamiento"
  },
  en: { 
      services: "Services",
      technologies: "About Us",
      ourProcess: "Our process",
      developSoftware: "Top Web and Mobile Apps Development Company",
      developCustomSoftware: "We build custom software.",
      workingUnderstand: "From custom software development to digital product innovation, our team of experts will help you take your business to the next level.",
      contact: "Get a free Quote",
      weTrust: "They trust us",
      doYouHaveAProject: "Do you have a project in mind?",
      consultantCall: "One of our consultants will call you free",
      leaveYourContact: "Leave us your details.",
      nameFirstName: "* Full Name",
      exampleEmail: "* example@email.com",
      mobile: "* Mobile",
      country: "* Country",
      whatTypeDevelopment: "* What type of development do you need?",
      webPage: "Web Page",
      saasDevelopment: "SaaS development",
      apps: "iOS and Android Aplications",
      webAndApps: "Web Platform and Web Apps",
      uxui: "User Experience Design and User Interface Desing",
      enhacement: "Maintenance and improvements on a current development",
      consultancy: "Consultancy",
      projectStatus: "* What status is your project in?",
      iHaveIdea: "I got the idea",
      iHaveMVP: "I have a prototype",
      isWorking: "My project is already working",
      howBigIsYourBusiness: "* How big do you consider your business?",
      sizeBusiness: "Independent / Professional / Entrepreneur",
      startup: "Startup",
      llc: "Small Business",
      corporation: "Corporation",
      timeDoYouNeed: "* In what time do you need your project completed?",
      days: "Days",
      weeks: "Weeks",
      months: "Months",
      describeProject: "What is your project about? Tell us briefly",
      sent: "Submit Form",
      ourServices: "Our services",
      consultancyDesc: "We become part of your business, to give a clear vision of what we are good at, create technology, do you have doubts where to start? we can help you.",
      webAppsProgresive: "Web and mobile app development",
      webAppsProgresiveDesc: "We create engaging and responsive web and mobile apps that help them to reach more customers and to increase their sales.",
      appsDevelopment: "AI Agents, AI models and machine learning",
      appsDevelopmentDesc: "We help our clients to incorporate different AI models and ML technologies to their business to make it more efficient and smarter.",
      ux: "UI/UX design and development",
      uxDesc: "We help you to create beautiful and user-friendly interfaces that make their software easy to use.",
      uxuiTitle: "SaaS & MVP creation",
      uxuiDesc: "We help you build your first MVP or your complete SaaS platform.",
      crmWha: "Custom software development",
      crmWhaDesc: "We build tailored software solutions that fit their business needs and goals.",
      empathize: "Empathize",
      define: "Define",
      ideate: "Think",
      develop: "Develop",
      measure: "Launch",
      ourTechnologies: "Our Stack",
      ourTechnologiesDesc: "We work with the best technologies on the market, to create and launch products quickly. ",
      slogan: "Whatever be said, be done",
      information: "Information",
      thankYou: "Thank you! 🙌 We will contact you shortly. 😊",
      initiate: "Initiate",
      analyze: "Analyze",
      design: "Design",
      develop: "Develop",
      qa: "QA",
      launch: "Launch"
  }
}

export default class Translate {
  static get(name, defaultLabel) {
    const lang = Storage.getItem('lang') || 'en'
    return translate[lang][name] ? translate[lang][name] : defaultLabel
  }
}
