import React from 'react';
import './Footer.css';
import Translate from './../../utils/Translate';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'; // Social icons

function Footer() {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Products Section */}
                <div className="footer-section">
                    <h3>{Translate.get('products', 'Services')}</h3>
                    <ul>
                        <li><a href="#">{Translate.get('crmWha', '')}</a></li>
                        <li><a href="#">{Translate.get('webAppsProgresive', '')}</a></li>
                        <li><a href="#">{Translate.get('appsDevelopment', '')}</a></li>
                        <li><a href="#">{Translate.get('uxuiTitle', '')}</a></li>
                        <li><a href="#">{Translate.get('ux', '')}</a></li>
                        <li><a href="#">{Translate.get('consultancy', '')}</a></li>
                    </ul>
                </div>

                {/* Company Section */}
                <div className="footer-section">
                    <h3>{Translate.get('company', 'Company')}</h3>
                    <ul>
                        <li><a href="https://www.linkedin.com/company/addabra">{Translate.get('about', 'About')}</a></li>
                        <li><a href="https://www.linkedin.com/company/addabra">{Translate.get('careers', 'Careers')}</a></li>
                        <li><a href="#privacy">{Translate.get('privacy', 'Privacy')}</a></li>
                        <li><a href="#terms">{Translate.get('terms', 'Terms')}</a></li>
                    </ul>
                </div>

                {/* Resources Section */}
                <div className="footer-section">
                    <h3>{Translate.get('resources', 'Resources')}</h3>
                    <ul>
                        <li><a href="#contacto">{Translate.get('contact', 'Contact')}</a></li>
                        <li><a href="#blog">{Translate.get('blog', 'Blog')}</a></li>
                    </ul>
                </div>

                {/* Follow Us Section */}
                <div className="footer-section">
                    <h3>{Translate.get('followUs', 'Follow Us')}</h3>
                    <div className="social-icons">
                        <a href="https://www.linkedin.com/company/addabra" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    </div>
                </div>
            </div>

            <div className="footer-bottom">
                <p>
                    {Translate.get('copyright', 'Copyright')} © {new Date().getFullYear()} Addabra, LLC. {Translate.get('slogan', 'Whatever be said, be done')}.
                </p>
                <p>
                    <a href="#terms">{Translate.get('termsOfUse', 'Terms of Use')}</a> | <a href="#privacy">{Translate.get('privacyPolicy', 'Privacy Policy')}</a>
                </p>
            </div>
        </footer>
    );
}

export default Footer;